import {
  smartContractDevelopmentFaq,
  artMarketplaceFaq,
  digitalEventsExhibitionFaq,
  votingDaoFaq,
  cryptocurrencyWalletDevelopmentFaq,
  dapBuilderFaq,
  multiSegFaq,
  revenueDistributorFaq,
  tokenIcoFaq,
  marketplaceFaq,
  aiIntegrationFaq,
  icpDevelopmentFaq,
  blockchainFaq,
  metaverseFaq,
  softwareFaq,
} from "./faq.config";

interface SEOEntity {
  "@type": string;
  name: string;
  acceptedAnswer: {
    "@type": string;
    text: string;
  };
}

interface SEOConfig {
  title: string;
  description: string;
  keywords?: string;
  organizationSchema?: {
    "@context": string;
    "@type": string;
    name: string;
    alternateName: string;
    url: string;
    logo: string;
    sameAs: string[];
  };
  searchSchema?: {
    "@context": string;
    "@type": string;
    url: string;
    potentialAction: {
      "@type": string;
      target: string;
      "query-input": string;
    };
  };
  type?: string;
  faqSchemaEntities?: SEOEntity[];
}

export const homeSeo: SEOConfig = {
  title: "Metachain Technologies: Pioneers in Software, AI & Blockchain Solutions",
  description:
    "We are a Canadian software development company, specializes in software solutions, AI integration, Blockchain development, and Metaverse technologies. Contact us for your success.",
  keywords: "Software Development Company, custom software development company",
  organizationSchema: {
    "@context": "https://schema.org/",
    "@type": "Organization",
    name: "Metachain Technologies",
    alternateName: "Metachain Technologies Inc.",
    url: "https://mcti.io/",
    logo: "https://mcti.com/io/logo.svg",
    sameAs: [
      "https://www.facebook.com/MetachainTechnologies",
      "https://twitter.com/MetachainInc",
      "https://www.linkedin.com/company/metachain-technologies-inc",
    ],
  },
  searchSchema: {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: "https://mcti.io/",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://mcti.io/?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  },
  type: "website",
};

export const mainPagesSeo: Record<string, SEOConfig> = {
  // products: {
  //   title: "White-label Solutions Products | Metachain Technologies",
  //   description:
  //     "Discover Metachain's white label products, Smart contracts, NFT Marketplace, ICO, DAO and more. Affordable products designed to transform your projects now.",
  //   keywords:
  //     "Software Development Company, custom software development company, software development company in india, software development company in usa, software development company in uk, software development company in canada, software development company in australia, software development company in singapore, software development company in malaysia, software development company in dubai, software development company in uae, software development company in germany, software development company in france, software development company in spain, software development company in italy, software development company in netherlands, software development company in switzerland, software development company in denmark, software development company in norway, software development company in sweden, software development company in finland, software development company in ireland, software development company in new zealand, software development company in south africa",
  // },
  // services: {
  //   title: "Advanced Development services | Metachain Technologies",
  //   description:
  //     "Metachain offers tailored AI, Blockchain, Metaverse, and software development services to drive growth and enhance efficiency. Discover our services here.",
  //   keywords:
  //     "Software Development Company, custom software development company, software development company in india, software development company in usa, software development company in uk, software development company in canada, software development company in australia, software development company in singapore, software development company in malaysia, software development company in dubai, software development company in uae, software development company in germany, software development company in france, software development company in spain, software development company in italy, software development company in netherlands, software development company in switzerland, software development company in denmark, software development company in norway, software development company in sweden, software development company in finland, software development company in ireland, software development company in new zealand, software development company in south africa",
  // },
  blockchain: {
    title: "Blockchain Development Services | Metachain Technologies",
    description: `Customized Blockchain solutions by experts. Blockchain development services for
    scalability, efficiency, and security. Contact Metachain technologies now
    `,
    faqSchemaEntities: blockchainFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  software: {
    title: "Comprehensive Software Development Services",
    description:
      "End-to-end software development solutions by Metachain Technologies, specialize in front-end, back-end, full-stack, and security software development.",
    keywords:
      "software development services, custom software development services, software development company",
    faqSchemaEntities: softwareFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  ai: {
    title: "AI Development and Integration Services | Metachain Technologies",
    description:
      "Metachain provides tailored AI solutions, including machine learning, NLP, computer vision, and deep learning. Experience the future of AI integration now.",
    keywords:
      "ai development services, ai software development services, ai application development services",
    faqSchemaEntities: aiIntegrationFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
};

export const servicesSeo: Record<string, SEOConfig> = {
  icp: {
    title: "Internet Computer Protocol (ICP) Services | Metachain Technologies",
    description:
      "Explore Metachain's ICP development, enabling projects to harness Internet Computer Protocol for secure and efficient DApp development. Click to learn more.",
    keywords: "ICP development",
    faqSchemaEntities: icpDevelopmentFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "smart-contracts": {
    title: "Custom Smart Contracts Services | Metachain Technologies",
    description:
      "Custom smart contract solutions by experts. discover our smart contract development services for scalability, efficiency, and security. Contact us now.",
    keywords:
      "smart contract development service, custom smart contract development services, Smart contract audit",
    faqSchemaEntities: smartContractDevelopmentFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },

  metaverse: {
    title: "Leading Metaverse Technology Services | Metachain Technologies",
    description:
      "Comprehensive Metaverse solutions, including Metaverse application development, integration, and consultancy. By Metachain Technologies, your partner to success",
    faqSchemaEntities: metaverseFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
};

export const productsSeo: Record<string, SEOConfig> = {
  "art-marketplace": {
    title: "Art NFT Marketplace | Metachain Technologies",
    description:
      "Discover Metachain's NFT Art Marketplace, a white label platform to promote and sell digital artwork as NFTs. check our NFT marketplace demo today.",
    keywords:
      "NFT marketplace development, NFT marketplace development company, NFT marketplace development services, White label NFT marketplace development, Whitelabel NFT marketplace, NFT marketplace white label, Metaverse NFT marketplace development company, NFT marketplace developer, NFT marketplace whitelabel, NFT marketplace solution, NFT marketplace platform development, White label NFT platform, White label NFT marketplace cost, NFT marketplace solutions, NFT marketplace developers",
    faqSchemaEntities: artMarketplaceFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "land-marketplace": {
    title: "NFT Land Marketplace | Metachain Technologies",
    description:
      "Dive into Metachain's Lands Marketplace, a blockchain-powered platform for trading virtual land in the Metaverse. Try our white-label solution demo now.",
    keywords: "metaverse land marketplace, metaverse marketplace land, ",
    faqSchemaEntities: marketplaceFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "events-exhibition": {
    title: "Digital Event Exhibitions | Metachain Technologies",
    description: `The future of event exhibitions developed by Metachain Technologies. High-performance,
    real-time solutions designed to elevate your digital events. Join here`,
    keywords: "event exhibition services, events exhibition, exhibition event management services",
    faqSchemaEntities: digitalEventsExhibitionFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "token-ico": {
    title: "Token ICO development | Metachain Technologies",
    description:
      "Launch your own Token with ease using our ICO development services. We provide end-to-end crypto solutions, from ideation to execution. Contact us today.",
    keywords:
      "ico development services, ico token development company, ico coin development services, ico token development, ico blockchain development, ico token developer",
    faqSchemaEntities: tokenIcoFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  voting: {
    title: "DAO white-label solutions | Metachain Technologies",
    description:
      "Empower your community with our DAO solutions. Build your decentralized secured ecosystem with tier 1 DAO protocol development services.",
    keywords:
      "dao development company, dao company, dao voting, dao development, dao voting system",
    faqSchemaEntities: votingDaoFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "revenue-distributor": {
    title: "Advanced Revenue Distributor | Metachain Technologies",
    description:
      "Maximize earnings with our NFT blockchain based revenue distributor. Distribute revenue across multiple channels seamlessly . Contact Metachain tech now",
    keywords: "",
    faqSchemaEntities: revenueDistributorFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  wallets: {
    title: "Crypto Wallet development | Metachain Technologies",
    description:
      "Discover our white label crypto wallet solution. We offers crypto wallet development services which prioritize security and customization. Try a demo here",
    keywords:
      "White Label Crypto Wallet, White Label Crypto Wallet Development Services, White Label Cryptocurrency Wallet Development, White Label Cryptocurrency Wallet, White Label Wallet Solution",
    faqSchemaEntities: cryptocurrencyWalletDevelopmentFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "multi-sig": {
    title: "Multi-sig Contract and wallet | Metachain Technologies",
    description:
      "Metachain's Multisig Contract and Wallet development enhances security and trust on the blockchain, ideal for complex financial processes. Learn more here",
    keywords: "Multisig Contract and Wallet development",
    faqSchemaEntities: multiSegFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
  "dapp-builder": {
    title: "Dapp Builder | Decentralized Apps | Metachain Technologies",
    description:
      "Metachain's Dapp Builder empowers developers to create and deploy secure and transparent decentralized applications (DApps) on blockchain. Learn more here.",
    keywords: "dapp builder",
    faqSchemaEntities: dapBuilderFaq.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: { "@type": "Answer", text: item.answer },
    })),
  },
};

export const teamSeo: Record<string, SEOConfig> = {
  main: {
    title: "Metachain Technologies Visionary Leadership: Matthew Connelly & Anas Madi",
    description: `Meet our leaders, CEO Matthew Connelly & CTO Anas Madi. Pioneers in AI, blockchain & software development, drive excellence with long expertise. Contact our tech trailblazers now`,
  },
  "anas-madi": {
    title: "Anas Madi | Metachain Technologies CTO",
    description: `Anas Madi, Metachain Technologies CTO and a visionary leader with long expertise in
      blockchain and AI development, leading 100+ developers team. contact here
      `,
    keywords: "Anas Madi, CEO, Co-Founder, Metachain Technologies",
  },
  "matthew-connelly": {
    title: "Mathew Connelly | Metachain Technologies CEO",
    description:
      "Matthew Connelly, CEO of Metachain. An industry veteran legend known for expertise in blockchain, digital marketing, and software development.",
    keywords: "Matthew Connelly, CEO, Co-Founder, Metachain Technologies",
  },
};

export const softwareDevSeo: Record<string, SEOConfig> = {
  "it-consulting": {
    title: "Metachain Technologies : Elite IT Consulting & Digital Strategy Mastery",
    description:
      "Our team offers top-tier IT Consulting services ranged from strategic planning to integration, solution architecture, and risk management. Partner with us for IT excellence.",
  },
  "custom-software": {
    title: "Metachain Technologies : Custom Software Development and expert developers",
    description: `Our team offers personalized software solutions, including customized application & UI/UX design, database development, and more. Engage with us for your unique business vision.`,
  },
  mobile: {
    title: "Metachain Technologies : OS, Andriod, & Hybrid Mobile App Development company",
    description: `Discover cutting-edge iOS & Android app solutions with our seasoned mobile app developers. Elevate your brand in the mobile world with sleek, high-performance apps. contact now`,
  },
  recruitment: {
    title: "Metachain Technologies : Developers Recruitment & Elite Talent ready to code",
    description: `Top tech talented, expert developers, Full Stack, AI Engineers, Blockchain developers and Mobile App Developers. Assemble your developers team today. lets discuss your needs here`,
  },
  web: {
    title: "Metachain technologies : Your Web Development Services Digital Craftsmanship",
    description: `Explore our comprehensive web development services, from front-end aesthetics to back-end functionality, CMS integration, and future-ready SaaS applications. We can deliver here.`,
  },
};

export const aiSeo: Record<string, SEOConfig> = {
  "custom-solution": {
    title: "Metachain Technologies: Tailored AI Solutions for Distinctive Business Needs",
    description:
      "Experience the power of AI solutions with Metachain. From ideation to implementation, our custom AI offerings are designed to align with your objectives. Contect us today.",
  },
  consulting: {
    title: "Metachain Technologies: Confidently simplify your AI transformation journey",
    description:
      "AI engineering experts offers AI road mapping, use case identification, infrastructure advisory, and data strategy. reach to us for strategic, seamless AI solutions. learn more",
  },
  "machine-learning": {
    title: "Metachain Technologies : (ML) Machine Learning & Technological Evolution",
    description: `Unleash the power of cutting-edge AI solutions, from Deep Learning to dynamic Neural Networks, real-time analytics, and advanced NLP. Elevate your business to the next frontier`,
  },
  chatbot: {
    title: `Metachain Technologies : Elevate your Business with conversational AI Chatbots`,
    description: `We provide Custom AI Chatbots, advanced GPT-based chatbots, Top CRM/ERP/CM bots, and much more. Contact our AI experts and get your perfect AI-powered chatbot developed today`,
  },
  "mvp-prototype": {
    title: `Metachain Technologies | AI MVP Prototype Development services for best ROI`,
    description: `Transform your AI vision into a tangible prototype with Metachain's AI MVP Prototype Development. Validate your AI concepts cost-effectively before full-scale development`,
  },
};

export const blockchainSeo: Record<string, SEOConfig> = {
  consulting: {
    title: "Metachain Technologies: Navigating the Blockchain Revolution with Expertise",
    description:
      "Expert consulting services. From strategy formulation to implementation, we guide businesses through every step of their blockchain journey. Unlock the future with us, click here",
  },
  nft: {
    title: " Metachain Technologies: Bring your NFT project to life with top NFT solutions",
    description:
      "Unlock the future of digital assets with our NFT solutions. Dive into custom NFT creation, NFT marketplace integration, & gaming innovations. Trade, craft, and elevate. join here",
  },
  "smart-contract": {
    title: "Metachain technologies: advanced Smart Contract development to boost trust",
    description: `leap into future with cutting-edge smart contracts. Secure, transparent, and efficient. Elevate your crypto financial game. Consult our smart contract development expert team now`,
  },
  icp: {
    title: "Metachain Technologies | tier one ICP Development services to get you profit",
    description: `Unlock the future with premier ICP development! explore cutting-edge network design, app innovation, top-tier security, and more. Dive into the ICP development revolution here`,
  },
  wallet: {
    title: `Metachain Technologies | Secure and Superior customized Cryptocurrency Wallets`,
    description: `The best customized crypto wallet solutions for your needs. Experience seamless multi-currency management on web & mobile. contact our Crypto experts for more information today`,
  },
  metaverse: {
    title: `Metachain Technologies: Be ready to Lead Metaverse Technology Innovation`,
    description: `Discover next-gen Metaverse solutions: Dive into VR realms, enhance with AR, personalize avatars, and build your digital NFT marketplace. Embrace the Metaverse future with us now`,
  },
  "white-label": {
    title: `Metachain Technologies: Advanced Ready-to-go Blockchain White-Label Solutions`,
    description: `Explore top-tier NFT, DAO, dApp, and blockchain white-label solutions. Dive into free demos and gain insights from blockchain experts. Elevate your crypto journey with us today.`,
  },
};
